<template>
  <div>
    <br />
    <div class="button-group">
      <el-button type="success" @click="loadDefault" style="margin-right: 10px;">默认</el-button>
      <el-button type="success" @click="loadReview" style="margin-right: 10px;">是否重点复核</el-button>
      <el-button type="success" @click="loadPreliminary">是否已初步复核</el-button>
    </div>

    <br />

    <div class="input-container">
      <el-input :placeholder="placeholderText" v-model="filterQuery" class="input-with-select">
        <el-select
          v-model="filterQueryHeader"
          filterable
          slot="prepend"
          style="width: 150px;"
          placeholder="请选择"
        >
          <el-option
            v-for="(label, prop) in tempForm"
            :key="prop"
            :label="label"
            :value="prop"
          ></el-option>
        </el-select>
        <el-button slot="append" @click="addFilter">添加搜索条件</el-button>
        <el-button slot="append" icon="el-icon-search" @click="filterData"></el-button>
      </el-input>
    </div>

    <div class="filter-tags">
      <el-tag
        v-for="(filter, index) in filters"
        :key="index"
        closable
        @close="removeFilter(index)"
        style="margin: 5px;"
      >
        {{ filter.key }}={{ filter.value }}
      </el-tag>
      <el-button type="danger" @click="clearFilter" v-show="filters.length">
        清除所有筛选条件
      </el-button>
    </div>

    <br />

    <div :style="{ height: pageHeight + 'px' }">
      <el-table :data="tableData" style="width: 100%;" :max-height="pageHeight" border>
        <!-- 表格列定义 -->
        <!-- 摘要列 -->
        <el-table-column prop="abstracts" label="摘要" width="180">
          <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title="摘要"
              width="800"
              trigger="click"
              :content="scope.row.abstracts"
              :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
              :open-delay="300"
            >
              <el-button type="text" slot="reference" class="text-ellipsis">
                {{ scope.row.abstracts }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 氨基酸序列列 -->
        <el-table-column prop="aminoAcidSeq" label="氨基酸序列" width="180">
          <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title="氨基酸序列"
              width="800"
              trigger="click"
              :content="scope.row.aminoAcidSeq"
              :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
              :open-delay="300"
            >
              <el-button type="text" slot="reference" class="text-ellipsis">
                {{ scope.row.aminoAcidSeq }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <!-- 其他列 -->
        <el-table-column prop="applicationDate" label="申请日期" width="180"></el-table-column>
        <el-table-column prop="applicationNumber" label="申请号" width="180"></el-table-column>
        <el-table-column prop="basePairSeq" label="碱基对序列" width="180">
          <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title="碱基对序列"
              width="800"
              trigger="click"
              :content="scope.row.basePairSeq"
              :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
              :open-delay="300"
            >
              <el-button type="text" slot="reference" class="text-ellipsis">
                {{ scope.row.basePairSeq }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="ec" label="EC号" width="180">
          <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title="EC号"
              width="800"
              trigger="click"
              :content="scope.row.ec"
              :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
              :open-delay="300"
            >
              <el-button type="text" slot="reference" class="text-ellipsis">
                {{ scope.row.ec }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="enzyme" label="酶" width="180"></el-table-column>
        <el-table-column prop="generalFormula" label="通式"></el-table-column>
        <el-table-column prop="pagesNumber" label="页数" width="180" class-name="word-break"></el-table-column>
        <el-table-column prop="title" label="专利名称" width="180" class-name="word-break"></el-table-column>
        <el-table-column prop="product" label="产物" width="180" class-name="word-break"></el-table-column>
        <el-table-column prop="reactionDescription" label="反应条件" width="180" class-name="word-break">
          <template slot-scope="scope">
            <el-popover
              placement="bottom"
              title="反应条件"
              width="800"
              trigger="click"
              :content="scope.row.reactionDescription"
              :popper-options="{ boundariesElement: 'viewport', removeOnDestroy: true }"
              :open-delay="300"
            >
              <el-button type="text" slot="reference" class="text-ellipsis">
                {{ scope.row.reactionDescription }}
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="reactionPagesNumber" label="反应页数" width="180"></el-table-column>
        <el-table-column prop="seqLabel" label="序列标签" width="180"></el-table-column>
        <el-table-column prop="substrate" label="底物" width="180"></el-table-column>
        <el-table-column prop="substrateLabel" label="底物标签" width="180"></el-table-column>
        <el-table-column prop="verification" label="信息是否核对" width="180"></el-table-column>
        <el-table-column prop="pdfName" label="PDF文件名" width="180">
          <template slot-scope="scope">
            <a :href="scope.row.pdfName" target="_blank">点击查看pdf</a>
          </template>
        </el-table-column>
        <!-- 操作列 -->
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <div class="operation-buttons">
              <el-button
                @click="edit(scope.row)"
                type="primary"
                size="mini"
                class="operation-button"
              >编辑</el-button>
              <el-button
                @click="update(scope.row)"
                type="success"
                size="mini"
                class="operation-button"
              >初步复核</el-button>
              <el-button
                @click="markImportant(scope.row)"
                type="warning"
                size="mini"
                class="operation-button"
              >重点复核</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 编辑对话框 -->
    <el-dialog
      title="编辑信息"
      :visible.sync="editDialogVisible"
      width="60%"
      :before-close="handleDialogClose"
    >
      <el-tabs v-model="activeTab">
        <!-- 基本信息选项卡 -->
        <el-tab-pane label="基本信息" name="basicInfo">
          <el-form ref="editForm" :model="form" :rules="rules" label-width="120px">
            <el-row :gutter="20">
              <!-- 基本信息 -->
              <!-- 申请号 -->
              <el-col :span="12">
                <el-form-item label="申请号" prop="applicationNumber">
                  <el-input v-model="form.applicationNumber" disabled></el-input>
                </el-form-item>
              </el-col>
              <!-- 专利名称 -->
              <el-col :span="12">
                <el-form-item label="专利名称" prop="title">
                  <el-input v-model="form.title" disabled></el-input>
                </el-form-item>
              </el-col>
              <!-- 发明人 -->
              <el-col :span="12">
                <el-form-item label="发明人" prop="inventor">
                  <el-input v-model="form.inventor"></el-input>
                </el-form-item>
              </el-col>
              <!-- 申请日期 -->
              <el-col :span="12">
                <el-form-item label="申请日期" prop="applicationDate">
                  <el-date-picker
                    v-model="form.applicationDate"
                    type="date"
                    placeholder="选择日期"
                    style="width: 100%;"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 摘要 -->
              <el-col :span="24">
                <el-form-item label="摘要" prop="abstract">
                  <el-input
                    type="textarea"
                    v-model="form.abstract"
                    :rows="4"
                    placeholder="请输入摘要"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <!-- 酶信息选项卡 -->
        <el-tab-pane label="酶信息" name="enzymeInfo">
          <el-form :model="form" label-width="120px">
            <el-row :gutter="20">
              <!-- 酶信息 -->
              <!-- 酶 -->
              <el-col :span="12">
                <el-form-item label="酶" prop="enzyme">
                  <el-input v-model="form.enzyme"></el-input>
                </el-form-item>
              </el-col>
              <!-- EC号 -->
              <el-col :span="12">
                <el-form-item label="EC号" prop="ec">
                  <el-input v-model="form.ec"></el-input>
                </el-form-item>
              </el-col>
              <!-- 底物 -->
              <el-col :span="12">
                <el-form-item label="底物" prop="substrate">
                  <el-input v-model="form.substrate"></el-input>
                </el-form-item>
              </el-col>
              <!-- 底物标签 -->
              <el-col :span="12">
                <el-form-item label="底物标签" prop="substrateLabel">
                  <el-input v-model="form.substrateLabel"></el-input>
                </el-form-item>
              </el-col>
              <!-- 产物 -->
              <el-col :span="12">
                <el-form-item label="产物" prop="product">
                  <el-input v-model="form.product"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <!-- 序列信息选项卡 -->
        <el-tab-pane label="序列信息" name="sequenceInfo">
          <el-form :model="form" label-width="120px">
            <el-row :gutter="20">
              <!-- 氨基酸序列 -->
              <el-col :span="24">
                <el-form-item label="氨基酸序列" prop="aminoAcidSeq">
                  <el-input
                    type="textarea"
                    v-model="form.aminoAcidSeq"
                    :autosize="{ minRows: 10 }"
                    placeholder="请输入氨基酸序列"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 碱基对序列 -->
              <el-col :span="24">
                <el-form-item label="碱基对序列" prop="basePairSeq">
                  <el-input
                    type="textarea"
                    v-model="form.basePairSeq"
                    :autosize="{ minRows: 10 }"
                    placeholder="请输入碱基对序列"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 序列标签 -->
              <el-col :span="12">
                <el-form-item label="序列标签" prop="seqLabel">
                  <el-input v-model="form.seqLabel"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <!-- 反应信息选项卡 -->
        <el-tab-pane label="反应信息" name="reactionInfo">
          <el-form :model="form" label-width="120px">
            <el-row :gutter="20">
              <!-- 反应条件 -->
              <el-col :span="24">
                <el-form-item label="反应条件" prop="reactionDescription">
                  <el-input
                    type="textarea"
                    v-model="form.reactionDescription"
                    :rows="10"
                    placeholder="请输入反应条件"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 反应页数 -->
              <el-col :span="12">
                <el-form-item label="反应页数" prop="reactionPagesNumber">
                  <el-input v-model="form.reactionPagesNumber"></el-input>
                </el-form-item>
              </el-col>
              <!-- 通式 -->
              <el-col :span="12">
                <el-form-item label="通式" prop="generalFormula">
                  <el-input v-model="form.generalFormula"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <!-- 其他信息选项卡 -->
        <el-tab-pane label="其他信息" name="otherInfo">
          <el-form :model="form" label-width="120px">
            <el-row :gutter="20">
              <!-- 页数 -->
              <el-col :span="12">
                <el-form-item label="页数" prop="pagesNumber">
                  <el-input v-model="form.pagesNumber"></el-input>
                </el-form-item>
              </el-col>
              <!-- 信息是否核对 -->
              <el-col :span="12">
                <el-form-item label="信息是否核对" prop="verification">
                  <el-input v-model="form.verification"></el-input>
                </el-form-item>
              </el-col>
              <!-- PDF文件名 -->
              <el-col :span="24">
                <el-form-item label="PDF文件名" prop="pdfName">
                  <el-input v-model="form.pdfName" disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'basicInfo', // 当前激活的选项卡
      placeholderText: '请输入内容',
      filters: [],
      tempForm: {},
      filterQuery: '',
      filterQueryHeader: '',
      state: '',
      tableData: [],
      tempTableData: [],
      maxTotal: 0,
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      pageHeight: 0,
      input: '',
      viewContent: '',
      editDialogVisible: false,
      value2: '',
      form: {},
      formLabelWidth: '120px',
      filterTableList: '',
      formFields: {
        '申请号': 'applicationNumber',
        '专利名称': 'title',
        '摘要': 'abstract',
        '发明人': 'inventor',
        '申请日期': 'applicationDate',
        '酶': 'enzyme',
        'EC号': 'ec',
        '底物': 'substrate',
        '底物标签': 'substrateLabel',
        '页数': 'pagesNumber',
        '产物': 'product',
        '信息是否核对': 'verification',
        '氨基酸序列': 'aminoAcidSeq',
        '碱基对序列': 'basePairSeq',
        '序列标签': 'seqLabel',
        '反应条件': 'reactionDescription',
        '反应页数': 'reactionPagesNumber',
        '通式': 'generalFormula',
        'PDF文件名': 'pdfName',
      },
      textareaFields: ['申请号', '专利名称', '摘要', 'PDF文件名'],
      onlyFilter: ['底物'],
      rules: {
        applicationNumber: [{ required: true, message: '申请号不能为空', trigger: 'blur' }],
        title: [{ required: true, message: '专利名称不能为空', trigger: 'blur' }],
        // 可以继续添加其他字段的验证规则
      },
    };
  },
  created() {
    this.pageHeight = this.getPageHeight();
    setTimeout(() => {
      this.loading = false;
    }, 500);

    this.$message({
      message: '请耐心等待数据加载',
      type: 'info',
    });

    this.initSelectOptions();
    this.$axios.get('/EnzymeInformationCheck?state=').then((response) => {
      response.data.data.forEach((item, index) => {
        item.id = index;
      });
      this.tableData = response.data.data;
      this.maxTotal = response.data.total;
      this.tempTableData = response.data.data;
    });
  },
  watch: {
    filterQueryHeader(newVal) {
      switch (newVal) {
        case 'substrate':
          this.placeholderText = '底物只支持单个查询';
          break;
        case 'applicationDate':
          this.placeholderText = '请输入日期,例如 2001-01-01，范围日期请多次添加条件';
          break;
        default:
          this.placeholderText = '请输入内容';
          break;
      }
    },
  },
  methods: {
    initSelectOptions() {
      this.tempForm = this.invertObject(this.formFields, [
        '酶',
        '申请号',
        '发明人',
        '申请日期',
        'EC号',
        '底物',
        '底物标签',
        '产物',
        '信息是否核对',
      ]);
    },
    invertObject(obj, excludeKeys = []) {
      return Object.keys(obj).reduce((acc, key) => {
        if (!excludeKeys.includes(key)) {
          acc[obj[key]] = key;
        }
        return acc;
      }, {});
    },
    addFilter() {
      if (this.filterQuery && this.filterQueryHeader) {
        const currentKey = this.tempForm[this.filterQueryHeader];
        const existingFilterIndex = this.filters.findIndex(
          (filter) => filter.key === currentKey
        );
        this.filterQuery = this.filterQuery.trim();

        if (existingFilterIndex !== -1 && this.onlyFilter.includes(currentKey)) {
          this.filters[existingFilterIndex].value = this.filterQuery;
        } else {
          this.filters.push({
            key: currentKey,
            value: this.filterQuery,
          });
        }
        this.filterQuery = '';
      }
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
      if (this.filters.length === 0) {
        this.tableData = this.tempTableData;
      }
    },
    filterData() {
      const filterMap = {};
      this.filters.forEach((filter) => {
        const key = this.formFields[filter.key];
        if (!filterMap[key]) {
          filterMap[key] = [];
        }
        filterMap[key].push(filter.value.toLowerCase());
      });
      this.tableData = this.tempTableData.filter((row) => {
        return Object.keys(filterMap).every((key) => {
          return filterMap[key].some((filterValue) => {
            return String(row[key]).toLowerCase().includes(filterValue);
          });
        });
      });
    },
    clearFilter() {
      this.filters = [];
      this.tableData = this.tempTableData;
    },
    markImportant(row) {
      this.$confirm('是否标记为重点复核', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '保存',
        cancelButtonText: '初始化标记',
      })
        .then(() => {
          this.$message({
            type: 'info',
            message: '保存修改',
          });
          this.$axios
            .post('/EnzymeInformationCheck/updateState', {
              applicationNumber: row.applicationNumber,
              state: '重点复核',
            })
            .then((response) => {
              console.log(response.data.status);
              


              if (response.data.status === 200) {
                this.$message({
                  message: response.data.msg,
                  type: 'success',
                });
              } else {
                this.$message({
                  message: '修改失败',
                  type: 'error',
                });
              }
            });
        })
        .catch((action) => {
          if (action === 'cancel') {
            this.$axios
              .post('/EnzymeInformationCheck/updateState', {
                applicationNumber: row.applicationNumber,
                state: '',
              })
              .then((response) => {
                if (response.data.status === 200) {
                  this.$message({
                    message: '初始化成功',
                    type: 'success',
                  });
                } else {
                  this.$message({
                    message: '修改失败',
                    type: 'error',
                  });
                }
              });
          }
          this.$message({
            type: 'info',
            message: action === 'cancel' ? '初始化成功' : '关闭',
          });
        });
    },
    update(row) {
      this.$confirm('是否标记为初步复核', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '保存',
        cancelButtonText: '初始化标记',
      })
        .then(() => {
          this.$message({
            type: 'info',
            message: '保存修改',
          });
          this.$axios
            .post('/EnzymeInformationCheck/updateState', {
              applicationNumber: row.applicationNumber,
              state: '初步复核',
            })
            .then((response) => {
              if (response.data.status === 200) {
                this.$message({
                  message: response.data.msg,
                  type: 'success',
                });
              } else {
                this.$message({
                  message: '修改失败',
                  type: 'error',
                });
              }
            });
        })
        .catch((action) => {
          if (action === 'cancel') {
            this.$axios
              .post('/EnzymeInformationCheck/updateState', {
                applicationNumber: row.applicationNumber,
                state: '',
              })
              .then((response) => {
                if (response.data.status === 200) {
                  this.$message({
                    message: '初始化成功',
                    type: 'success',
                  });
                } else {
                  this.$message({
                    message: '修改失败',
                    type: 'error',
                  });
                }
              });
          }
          this.$message({
            type: 'info',
            message: action === 'cancel' ? '初始化成功' : '关闭',
          });
        });
    },
    getPageHeight() {
      return (
        ((window.innerHeight ||
          document.documentElement.scrollHeight ||
          document.body.scrollHeight) /
          10) *
          9.2 -
        200
      );
    },
    edit(row) {
      this.form = { ...row };
      this.editDialogVisible = true;
    },
    handleDialogClose(done) {
      this.$confirm('确认关闭？未保存的修改将丢失')
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    submitEdit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$axios
            .post('/EnzymeInformationCheck/update', this.form, {
              headers: {
                token: localStorage.token,
              },
            })
            .then((response) => {
              this.$message({
                message: response.data.msg,
                type: 'success',
              });
              this.editDialogVisible = false
            });
        } else {
          this.$message({
            message: '请完善表单信息',
            type: 'warning',
          });
          return false;
        }
      });
    },
    loadTableData() {
      this.$axios.get('/EnzymeInformationCheck?state=' + this.state).then((response) => {
        this.tableData = response.data.data;
        this.maxTotal = response.data.total;
        this.tempTableData = response.data.data;
      });
    },
    loadDefault() {
      this.$message({
        message: '请耐心等待数据加载',
        type: 'info',
      });
      this.state = '';
      this.loadTableData();
    },
    loadReview() {
      this.$message({
        message: '请耐心等待数据加载',
        type: 'info',
      });
      this.state = '重点复核';
      this.loadTableData();
    },
    loadPreliminary() {
      this.$message({
        message: '请耐心等待数据加载',
        type: 'info',
      });
      this.state = '初步复核';
      this.loadTableData();
    },
  },
  mounted() {
    this.pageHeight = this.getPageHeight();
    window.addEventListener('resize', () => {
      this.pageHeight = this.getPageHeight();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getPageHeight);
  },
};
</script>

<style scoped>
.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-ellipsis {
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.input-with-select {
  width: 40%;
}

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}

.dialog-footer {
  text-align: right;
}

.word-break {
  word-break: break-all;
}

/* 分组标题样式 */
.group-title {
  margin: 20px 0 10px;
  font-size: 16px;
  color: #333;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 5px;
}

/* 表单项之间的间距 */
.el-form-item {
  margin-bottom: 20px;
}

/* 操作按钮容器样式 */
.operation-buttons {
  display: flex;
  flex-direction: column;
  align-items: center; /* 修改为居中对齐 */
}

/* 为操作按钮添加间隔 */
.operation-button {
  margin-bottom: 5px;
  width: 80px; /* 确保按钮宽度一致 */
}
</style>
  