<template>
  <div id="app">
    <div>
      <div v-show="this.show">
        <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
          <el-submenu index="1">
            <template slot="title">酶库及酶促反应</template>
            <router-link to="/DigitalEnzymeLibraryBox" class="no-underline">
              <el-menu-item index="1-1">数字化酶库</el-menu-item>
            </router-link>
            <router-link to="/SolidEnzymeLibrary" class="no-underline">
              <el-menu-item index="1-2">
                实体酶库
              </el-menu-item>
            </router-link>

            <router-link to="/CompoundLibrary" class="no-underline">
              <el-menu-item index="1-3">
                化合物库
              </el-menu-item>
            </router-link>
            <router-link to="" class="no-underline">
              <el-menu-item index="1-4">
                微生物库（未开发）
              </el-menu-item>
            </router-link>
            <el-menu-item index="1-5">酶促反应概率及查询（未开发）</el-menu-item>
            <el-menu-item index="1-6">底物-酶匹配查询（未开发）</el-menu-item>
            <el-menu-item index="1-7">多环境变量酶查询（未开发）</el-menu-item>
            <el-menu-item index="1-8">外部数据库查询入口（未开发）</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">信息录入及核对</template>
            <!-- <router-link to="/MutantInformationInput" class="no-underline"> -->
            <el-submenu index="6-1">
              <template slot="title">突变体信息</template>
              <el-menu-item index="6-1-1">湿实验目录</el-menu-item>
              <el-menu-item index="6-1-2">生物信息学</el-menu-item>
            </el-submenu>
            <!-- </router-link> -->
            <router-link to="/EnzymeLibraryInformationVerification" class="no-underline">
              <el-menu-item index="6-2">酶库等信息核对</el-menu-item>
            </router-link>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">蛋白及细胞因子（未开发）</template>
            <el-menu-item index="2-1">细胞因子及结构</el-menu-item>
            <el-menu-item index="2-2">主要蛋白及结构</el-menu-item>
            <el-menu-item index="2-3">人体主要通路</el-menu-item>
          </el-submenu>
          <el-submenu index="3">

            <template slot="title">产业到达及客户维护</template>
            <router-link to="/CustomerListLeads" class="no-underline">
              <el-menu-item index="3-1">目标客户名单及线索（测试版本）</el-menu-item>
            </router-link>
            <el-menu-item index="3-2">存量客户维护及合作（未开发）</el-menu-item>
            <el-menu-item index="3-3">营销计划及实施（未开发）</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">订单及推进信息</template>
            <el-menu-item index="4-1">订单概览</el-menu-item>
            <el-submenu index="4-2">
              <template slot="title">筛选优化</template>

              <el-menu-item index="4-2-2">实体酶库筛选（未开发）</el-menu-item>
              <el-menu-item index="4-2-3">反应条件智能优化（未开发）</el-menu-item>
            </el-submenu>
            <el-submenu index="4-3">
              <template slot="title">拓扑设计及验证</template>
              <el-menu-item index="4-3-1">拓扑预测</el-menu-item>
              <el-menu-item index="4-3-2">拓扑设计</el-menu-item>
              <el-menu-item index="4-3-3">湿实验验证</el-menu-item>
            </el-submenu>
            <el-menu-item index="4-4">定向进化</el-menu-item>
            <el-menu-item index="4-5">分析方法</el-menu-item>
            <el-menu-item index="4-6">发酵生产</el-menu-item>
            <el-menu-item index="4-7">质量控制</el-menu-item>
            <el-menu-item index="4-8">进销存管理</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">自主研发项目管理（未开发）</template>
            <el-menu-item index="5-1">预先研究</el-menu-item>
            <el-menu-item index="5-2">立项审核</el-menu-item>
            <el-menu-item index="5-3">研发进度管理</el-menu-item>
          </el-submenu>

          <el-submenu index="7">
            <template slot="title">更多信息（未开发）</template>
            <el-menu-item index="7-1">突变体及专利查重</el-menu-item>
            <el-menu-item index="7-2">下游应用查询</el-menu-item>
            <el-menu-item index="7-3">文献及专利信息查询</el-menu-item>
          </el-submenu>
          <el-submenu index="8">
            <template slot="title">设置</template>
            <el-menu-item index="8-1">界面设置（未开发）</el-menu-item>
            <router-link to="/RightsManagement" class="no-underline">
              <el-menu-item index="8-2">权限管理</el-menu-item>
            </router-link>
            <!-- <el-menu-item index="8-2">权限管理</el-menu-item> -->
          </el-submenu>
          <el-menu-item index="9" class="login-button">
            <template>

              <el-button type="info" round plain @click="exit()">退出</el-button>
            </template>


          </el-menu-item>
        </el-menu>


      </div>

      <router-view>
      </router-view>
    </div>

    <div>

    </div>
  </div>
</template>



<script>

import { EventBus } from './eventBus'
export default {
  name: 'HomeView',
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      isUserLoggedIn: localStorage.getItem('login') === 'true',
      show: false,
    };
  },


  created() {
    EventBus.$on('login-success', this.updateNavbar);
    EventBus.$on('login-fail', this.fail);
    EventBus.$on('Verify-permissions-fail', this.permissionsFail)
    localStorage.setItem('show', 'false');
    this.show = false;
    console.log('app created');
    this.$axios.get("/User/Token").then(response => {
      if (response.data == false) {
        EventBus.$emit('login-fail');

        this.$message({
          message: '登录已过期，请重新登录',
          type: 'warning'
        });
        this.$router.push('/login').catch(() => { });
        // 将导航栏隐藏
        localStorage.setItem('show', false);
        if (localStorage.getItem('show') == 'false') {
          this.show = false;
        }
        // 移除token
        localStorage.removeItem('token');
        console.log('token已过期');
        return
      }
      else {
        // 没有过期
        localStorage.setItem('show', 'true');
        if (localStorage.getItem('show') == 'true') {
          this.show = true;

        }
        console.log('token未过期');

      }
      // 登录成功触发 login-success 事件
      EventBus.$on('login-success', this.updateNavbar);

      // 检测是否存在token，如果存在，则直接跳转到首页

      console.log('token', localStorage.getItem('token'));

      if (localStorage.getItem('token')) {
        EventBus.$emit('login-success');
      }
      else {
        EventBus.$emit('login-fail');
        this.$router.push('/login').catch(() => { });
      }
    });
  },
  beforeDestroy() {
    EventBus.$off('login-fail', this.updateNavbar);
    EventBus.$off('login-success', this.updateNavbar);
    EventBus.$off('Verify-permissions-fail', this.permissionsFail);

  },
  // 监控 localStorage 中的 show 属性,

  methods: {
    permissionsFail() {
      // 提示权限验证失败
      this.$message({
        message: '没有权限访问',
        type: 'error'
      })
    }
    ,
    updateNavbar() {
      // 更新导航栏状态
      console.log("更新导航栏状态");
      this.show = true;
    },
    fail() {
      this.show = false;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    toLogin() {
      console.log('toLogin');
      if (localStorage.getItem('token')) {
        this.$message({
          message: '您已登录',
          type: 'warning'
        });
        this.isUserLoggedIn = true;
      }
      else {
        this.show = false;
        this.$router.push('/login');

      }
    },
    exit() {
      // 删除localStorage中的login标记
      localStorage.removeItem('login');
      localStorage.removeItem('token');
      this.isUserLoggedIn = false; // 更新响应式属性
      this.$message({
        message: '退出成功',
        type: 'success'
      });
      EventBus.$emit('login-fail');
      this.$router.push('/login');
    },
  }, computed: {
    isLoggedIn() {
      try {
        console.log("是否登录", localStorage.getItem('login') === 'true');
        return localStorage.getItem('login') === 'true';
      } catch (e) {
        return false;
      }
    }
  }
}
</script>
<style lang="less">
[hidden] {
  display: none !important;
}

/* 为导航栏设置Flexbox布局 */
.el-menu-demo {
  display: flex;
  justify-content: space-between;
  /* 两端对齐 */
  width: 100%;
  /* 全宽度 */
}

.el-menu-demo>.el-submenu,
.el-menu-demo>.el-menu-item {
  flex: 1;
  /* 所有子元素平分空间 */
}

/* 为 index=9 的选项单独设置 */
.el-menu-demo>.el-menu-item[index="9"] {
  flex: 0 0 20%;
  /* 不伸缩，固定占据20%的空间 */
}

/* 选项卡和子菜单标题居中 */
.el-menu-demo .el-submenu .el-submenu__title,
.el-menu-demo .el-menu-item {
  text-align: center;
  /* 文本居中 */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 如果有子菜单，确保子菜单项也居中 */
.el-menu-demo .el-submenu .el-menu {
  text-align: center;
}

/* 修正子菜单内部项的布局 */
.el-menu-demo .el-submenu .el-menu-item {
  display: block;
  /* 使子菜单项垂直排列 */
}

/* 去除特定菜单项的高亮效果 */
.el-menu-item.login-button.is-active,
.el-menu-item.login-button:not(.is-disabled):hover {
  background-color: transparent !important;
  /* 强制应用样式 */
}


/* 移除特定 router-link 的下划线 */
.no-underline {
  text-decoration: none;
  color: inherit;
}
</style>